import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../layout/_frame.vue'),
    redirect: to => {
      return {
        path: 'login'
      }
    },
    children: [{
        path: '/resource',
        name: 'resource',
        component: () => import('../views/resource/resourceList.vue')
      },
      {
        path: '/resourceEdit',
        name: 'resourceEdit',
        component: () => import('../views/resource/resourceEdit.vue')
      }
    ]

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,from)=>{
    
// })




export default router