import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'

const instance = axios.create({
    baseURL: '/',
    // headers: {
    //     'Content-Type': 'application/json',
    // },
})
// instance.defaults.headers.post["Content-Type"] = "application/json";
instance.interceptors.request.use(
    (config) => {
        if (sessionStorage.getItem('token')) {
            config.headers["Authorization"] = "Bearer" + " " + sessionStorage.getItem('token');
            // config.headers["Authorization"] = sessionStorage.getItem('token');
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        console.log(response, 3333)
        // 是否操作正常
        if (response.data.code == 200) {
            return response.data
        }
        else if(response.data.code == 401){
            Message.error(response.message);
            router.push('/login');
        } 
        else {
            Message.error(response.message);
            return Promise.reject(response.data)
        }
    },
    (error) => {
        console.log(error,8888)
        const res=error.response;
        if(res.data.code == 401){
            Message.error(res.data.message);
            router.push('/login');
        } 
        else {
            Message.error(res.data.message);
            return Promise.reject(res.data)
        }
        return Promise.resolve(res.data)
    }
)

export default instance