 import http from "./http";

 const Plugin = {
     install: Vue => {
         Vue.$http = http;
         Object.defineProperties(Vue.prototype, {
             $http: {
                 get() {
                     return http;
                 }
             }
         });
     }
 };

 export default Plugin;