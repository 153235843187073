import service from "./axios";
 
const http = {
    get(url, data) {
        return service.get(url, {
            params: data
        });
    },
    post(url, data) {
        return service.post(url, data);
    },
    upLoadFile(url, data) { 
        return service({
            url: url,
            method: "post",
            data: data,
            contentType: false,
            processData: false,
            dataType: "json"
        });
    },
}

export default http;