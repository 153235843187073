import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './styles/normalize.scss'
import Plugin from './http/index';
Vue.use(Plugin)

Vue.use(ElementUI)
// Vue.use(router)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')